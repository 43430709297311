import { sasagase } from "@sasagase/types";
import * as React from 'react';
import { Shipping, ShippingOrderItem } from '../../../types';
import { Barcode } from '../Barcode';
import { ItemListRow } from './ItemListRow';
import { PickingListHeader } from './PickingListHeader';
import { PickingListShipping } from './PickingListShipping';

export type ShippingOrderItemAlpha = ShippingOrderItem & {
	id: number;
	parentId?: number;
	idx?: number;
};

interface ItemGroup {
	parent: ShippingOrderItemAlpha;
	items: ShippingOrderItemAlpha[];
}

export interface PickingListProps {
	shipping: Shipping;
	skuPrefix: string;
	shippingPrefix: string;
	css?: string;
	settings: sasagase.GetOrderInstructionSetting[];
}

export const PickingList: React.FC<PickingListProps> = (props) => {
	const { shipping: { orders } } = props;
	if (orders.length > 1) {
		throw new Error();
	}
	const order = orders[0];

	const baseItems = orders.flatMap(order => order.items);
	// sku毎のgroupByを解除
	const singleItems: ShippingOrderItemAlpha[] = baseItems.flatMap((item) => {
		return item.ids.map((i) => ({
			...item,
			id: parseInt(i.id, 10),
			parentId: parseInt(i.parentId, 10),
			quantity: 1,
		}));
	});

	// 親にあたる商品を抽出
	const parentIds = [...new Set(singleItems.map((item) => item.parentId).filter(Boolean))];
	const parents = singleItems.filter((item) => parentIds.includes(item.id));
	const getParent = (parentId: number): ShippingOrderItemAlpha => {
		const parent = parents.find((p) => p.id === parentId);
		if (!parent) {
			throw new Error("親商品が見つかりません");
		}
		if (parent.parentId) {
			return getParent(parent.parentId);	// 最上位の親を取得
		}
		return parent;
	};

	// sku毎のgroupByのリストから最上位の親のSKUが異なるもののみ分離
	const items: ShippingOrderItemAlpha[] = baseItems.flatMap((item) => {
		const topParentGroupBy: Record<string, { id: number, parentId: number }[]> = {};
		for (const i of item.ids) {
			const parentKey = i.parentId ? getParent(parseInt(i.parentId, 10)).sku : 'parentless';
			if (!topParentGroupBy[parentKey]) {
				topParentGroupBy[parentKey] = [];
			}
			topParentGroupBy[parentKey].push({
				id: parseInt(i.id, 10),
				parentId: parseInt(i.parentId, 10),
			});
		}

		return Object.entries(topParentGroupBy).map(([parentKey, ids]) => ({
			...item,
			id: Math.min(...ids.map((i) => i.id)),
			parentId: parentKey === 'parentless' ? undefined : Math.min(...ids.map((i) => i.parentId)),
			quantity: ids.length,
		}));
	});

	// 親・子のセットを組み合わせ
	const groups: ItemGroup[] = [];
	for (const item of items) {
		if (!item.locationId) {
			continue;
		}

		if (item.parentId) {
			const parent = getParent(item.parentId);
			let group = groups.find((group) => group.parent.id === parent.id);
			if (!group) {
				group = { parent, items: [] };
				groups.push(group);
			}
			group.items.push({ ...item });
		} else {
			groups.push({ parent: { ...item }, items: [] });
		}
	}
	let idx = 1;
	groups.forEach((group) => {
		// 親の個数を子の個数(最小値)で更新
		if (group.items.length > 0) {
			group.parent.quantity = Math.min(...group.items.map((item) => item.quantity));
		}
		// idx振り直し
		if (group.parent.locationId) {
			group.parent.idx = idx++;
		} else {
			group.items.forEach((item) => item.idx = idx++);
		}
	});

	return (
		<div className="page">
			<PickingListHeader shipping={props.shipping} />
			<PickingListShipping shipping={props.shipping} />
			<section className="detail">
				<h1>明細</h1>
				<table>
					<thead>
						<tr>
							<th className="w10 textC">#</th>
							<th className="w100">SKU</th>
							<th>商品名／商品オプション</th>
							<th className="w40">個数</th>
							<th className="w80">備考</th>
						</tr>
					</thead>
					{groups.map((group) =>
						<tbody key={group.parent.id}>
							{group.parent &&
								<ItemListRow itemType="parent" item={group.parent} key={`${group.parent.sku}-parent`} prefix={props.skuPrefix} settings={props.settings} />
							}
							{group.items.map((item, idx) =>
								<ItemListRow itemType="child" item={item} key={`${item.sku}-${idx}`} prefix={props.skuPrefix} settings={props.settings} />
							)}
						</tbody>
					)}
				</table>
				<div className="detailOther">
					<div className="barcode"><Barcode id="barcode" jsbarcode-value={ props.shippingPrefix + props.shipping.id } jsbarcode-height={ 50 }/></div>
					<div className="postage">
						<table>
							<thead>
								<tr>
									<th className="w80">送料</th>
									<th className="w80">代引き手数料</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td className="textR">{ order.detail.postagePrice ? order.detail.postagePrice.toLocaleString() : '-' } 円</td>
									<td className="textR">{ order.detail.deliveryPrice ? order.detail.deliveryPrice.toLocaleString() : '-' } 円</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</section>
		</div>
	);
};