/**
 * 日付文字列をDateに変換する
 * 日付だけのISO8601の文字列(`2024-09-17`など)は00:00:00JSTとして扱う
 * それ以外の形式の文字列はそのまま`new Date`と同様(環境依存)に処理される
 */
export function parseDateAsJST(date: string): Date {
	const {
		groups: {
			d = '',
			t = 'T00:00:00',
			tz = '+09:00',
		} = {},
	} = date.match(/^(?<d>[0-9]{4}-\d{2}-\d{2})(?<t>T\d{2}:\d{2}:\d{2}(?:\.\d+)?)?(?<tz>[+-]\d{2}(?::\d{2})?|Z)?$/) ?? {};
	if (d) {
		// dに何かしら文字列が入っていれば少なくともISO8601形式のはず(数値の範囲は検査していないが)
		return new Date(`${d}${t}${tz}`);
	}
	return new Date(date);
}
export default parseDateAsJST;
